import React from 'react';
import { formatToMoney } from '../../utils/common';

const Resumen = ({data = [], rate = 1}) => {
  
    const importe = data.reduce((actual, nuevo) => {
        return actual + nuevo.total;
    },0);

    const descuento  = data.reduce((actual, nuevo) => {
        return actual + nuevo.discount;
    },0);

    const iva = 0
    const total = data.reduce((actual, nuevo) => {
        return actual + nuevo.total;
    },0);

    const totalC$  = total * rate;

    return (     
        <div className="float-right">
            <table>
                <tbody style={{fontSize : '20px'}}>

                    <tr>
                        <td>Sub total:</td>
                        <td className="text-right">{formatToMoney(importe)}</td>
                    </tr>
                    <tr>
                        <td>Descuento:</td>
                        <td className="text-right">{formatToMoney(descuento)}</td>
                    </tr>
                    <tr>
                        <td>IVA:</td>
                        <td className="text-right">{formatToMoney(iva)}</td>
                    </tr>
                    <tr>
                        <td>Total:</td>
                        <td className="text-right"><b>{formatToMoney(total)}</b></td>
                    </tr>             
                           
                </tbody>
            </table>
        </div>
    );
}

export default Resumen;
