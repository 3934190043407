import { createProxy, createProxyBase } from "./proxy";

const uri = {   
    areas : createProxyBase('areas'),  
    bills : createProxyBase('bills'),   
    clients : createProxyBase('clients'),   
    compras: createProxyBase('compras'),      
    providers : createProxyBase('providers'),  
    estados: createProxyBase('estados'),
    file: createProxy('', 'percapitas/post/file'),
    fileRates: createProxy('', 'rates/post/file'),
    inPutProducts: createProxyBase('inPutProducts'),  
    outPutProducts: createProxyBase('outPutProducts'),  
    traslates: createProxyBase('traslates'),    
    products: createProxyBase('products'),
    roles:createProxyBase('roles'),
    users:createProxyBase('users'),
    rates:createProxyBase('rates'),
    receivables: createProxyBase('receivables'),
    vendors: createProxyBase('vendors'),
    areaProducts : areaId => createProxyBase(`area/${areaId}/products`) 
};

uri.resources= roleId => `roles/${roleId}/resources`;
uri.products.getByArea = areaId => `products/getbyarea/${areaId}`;
uri.clients.asCatalog = 'clients/asCatalog';

uri.receivables.byBill = billId =>`receivables/bill/get/${billId}`;

uri.account = 'account/auth';
uri.changepassword = 'account/changepassword';
uri.resetPassword = 'account/resetpassword';


export const routeReset = props => props.history.push({ pathname : '/app/navig' }, { returnUrl: props.location.pathname }); 


export default uri;