import React from 'react';
import BlockHeader from '../../../components/shared/BlockHeader';
import Title from '../../../components/shared/Title';
import DataGrid, {
    Column,
    ColumnChooser,
    Editing,
    Export,
    FilterRow,
    HeaderFilter,
    Lookup,
    Pager,
    Paging,
    Button as ButtonGrid,
} from 'devextreme-react/data-grid';
import { createStoreLocal } from '../../../utils/proxy';
import uri from '../../../utils/uri';
import { store } from '../../../services/store';
import { inPutProductStates } from '../../../data/enums';
import { dataAccess, formatDate, formatDateTime } from '../../../data/app';
import CustomButton from '../../../components/buttons/CustomButton';
import { useDispatch } from 'react-redux'
import { dialogInputProduct } from '../../../store/inPutProduct/inPutProductDialogReducer';
import useAuthorization from '../../../hooks/useAuthorization';
import { dataFormatId } from '../../../utils/common';
import { addMenu } from '../../../components/grids/Menu';
import { onToolbar } from '../../../components/grids/ToolBar';

const InPutProducts = (
    {
        title = "Entrada de entrada inventario",
        btnAddText = "Crear entrada",
        typeId = null,
        icon = "",
        Component = null,
        resourcesId = null, 
        dialog = dialogInputProduct
    }) => {

    const { authorized } = useAuthorization([resourcesId, dataAccess.access]);

    let dataGrid = React.createRef();
    const dispatch = useDispatch();

    const reload = e => dataGrid.current.instance.refresh();
    

    const onRowPrepared = (e) => {
        if (e.rowType == 'data') {
            
            if (e.data.stateId == inPutProductStates.noActivo)
                e.rowElement.classList.add('no-activo');

        }
    }

    const showDialog = (id) => dispatch(dialog({ open: true, id }));    

    const addMenuItems = (e) => {
        addMenu(e, [{
            text: `Ver ${title}`,
            icon: 'find',
            onItemClick: () => showDialog(e.row.data.id)
        }])
    }

    const onToolbarPreparing = onToolbar({ export : true } , dataGrid);

    return authorized(
        <div className="container">
            <Title title={title} />
            <BlockHeader title={title} icon={icon} >
                <CustomButton
                    text={btnAddText}
                    icon='plus'
                    onClick={() => showDialog(0)}
                />
            </BlockHeader>
            <Component onSave={reload} typeId={typeId} />

            <DataGrid id="gridContainer"
                ref={dataGrid}
                selection={{ mode: 'single' }}
                dataSource={store({ uri: uri.inPutProducts, remoteOperations: true, extraParameter: typeId ? { key: 'typeId', value: typeId } : null })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                onRowPrepared={onRowPrepared}
                onContextMenuPreparing={addMenuItems}
                onToolbarPreparing={onToolbarPreparing}
                remoteOperations={{
                    paging: true,
                    filtering: true
                }}
            >
                <Paging defaultPageSize={20} />
                <Pager
                    showInfo={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <Export enabled={false} fileName={title} allowExportSelectedData={true} />
                <Column dataField="id" caption='Numero' width={100} cellRender={dataFormatId} />
                <Column dataField="date" caption='Fecha' dataType='date' format={formatDate} width={90} />
                <Column dataField="areaId" caption="Area" width={170}>
                    <Lookup disabled={true} dataSource={createStoreLocal({ name: 'area' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="typeId" caption="Tipo Entrada" width={150}>
                    <Lookup disabled={true} dataSource={createStoreLocal({ name: 'inPutProductType' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="reference" caption='Referencia' width={100} />
                <Column dataField="observation" caption='Observacion' allowHeaderFiltering={false} allowFiltering={false}/>
                <Column dataField="stateId" caption="Estado" width={90}>
                    <Lookup disabled={true} dataSource={createStoreLocal({ name: 'inPutProductState' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="createAt" caption='Creando el' dataType='date' format={formatDateTime} width={180} />
                <Column dataField="createBy" caption='Creado Por' width={120} />
                <Column type="buttons" width={60}>
                    <ButtonGrid name="edit" icon="find" onClick={e => showDialog(e.row.data.id)}/>
                    <ButtonGrid name="delete" />
                </Column>
                <Editing
                    mode="popup"
                    allowDeleting={true}
                    allowUpdating={true}
                    useIcons={true}
                >
                </Editing>
            </DataGrid>
        </div>
    );
}

export default InPutProducts;
