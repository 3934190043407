import React from 'react';
import CustomButton from './CustomButton';

const ButtonForm = ({saving, textSaving, onClick, visible=true}) => {
    return <CustomButton
        text={`${saving?'Guardando...': textSaving}`}
        type="success"
        icon="save"
        stylingMode="contained"
        className="m-1"
        onClick={onClick}
        visible={visible}
        disabled={saving}
    />    
}

export default ButtonForm;
