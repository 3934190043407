
import React from 'react';
import { logoHome } from '../../data/logo';
import Title from '../shared/Title';
import Dashboard from './Dashboard';

export default function HomePage() {
    
    return (
        <div className="container text-center mt-10">
            <Title title="Inicio" />
            <Dashboard />
        </div>
    )
}