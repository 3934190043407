import React, { useEffect, useRef, useState } from 'react';
import { Button, NumberBox, SelectBox, Switch, } from 'devextreme-react';
import {
    Validator,
    RequiredRule
} from 'devextreme-react/validator';
import useAuthorization from '../../hooks/useAuthorization';
import { dataAccess, editorOptionsNumberBox, editorOptionsSelect, editorOptionsSwitch, resources } from '../../data/app';
import http from '../../utils/http';
import notify from 'devextreme/ui/notify';
import { useSelector,useDispatch } from 'react-redux';
import { setAppInfo } from '../../store/app/appActions';
import BlockHeader from '../../components/shared/BlockHeader';
import Title from '../../components/shared/Title';
import { DivForm, DivFormColumns } from '../../components/form/DivForm';
import { editorOptionsDropDown } from '../../components/dropdown/ClienteDropDown';
import { createStoreLocal } from '../../utils/proxy';

const AppConfig = () => {

    const { authorized } = useAuthorization([resources.app, dataAccess.access ]);
    const [saving, setSaving] = useState({
        loading : false,
        text : 'Guardar'
    });

    const dispatch = useDispatch();
    const { appInfo } = useSelector(store => store);

    const [app, setApp] = useState({...appInfo});
    

    const onFormSubmit = (e) => {

        e.preventDefault();

        setSaving({loading : true, text:'Guardando...'});
        http('about/set-info').asPost(app).then(resp => {

            notify('Datos actualizados');
            setSaving({loading : false, text:'Guardar'});
            dispatch(setAppInfo(app));
            
        }).catch(err => {

            notify(err, 'error');
            setSaving({loading : false, text:'Guardar'});

        });
    }

    useEffect(() => {
        setApp({...app, ...appInfo});
    }, [appInfo]);

    const title = 'Configuracion';

    console.log(app);

    return authorized(
        <div className="container small">
             <Title title={title} />
             <BlockHeader title='Configuracion del sistema' />     
             <form onSubmit={onFormSubmit}>
                <div className="dx-fieldset">
                    <div className="dx-fieldset-header">Configuracion de bodega principal</div>
                    <DivFormColumns title='Area'
                        description="Seleccione el area que desea como bodega pricipal">                        
                        <SelectBox                            
                            value={app.areaMainId}                           
                            onValueChanged={e => setApp(app => ({ ...app, areaMainId: e.value }))}
                            dataSource= {createStoreLocal({name : 'area'})}
                            {...editorOptionsSelect}
                        >
                            <Validator>
                                <RequiredRule message="Este campo es requerido" />
                            </Validator>
                        </SelectBox>
                    </DivFormColumns>
                    <DivFormColumns title='Validar precio' 
                        description="Validar que el precio siempre sea mayor al costo en las entradas de inventario">                              
                        <Switch style={{ float : "left" }}                        
                            value={app.validatePriceGreaterCost}                           
                            onValueChanged={e => setApp(app => ({ ...app, validatePriceGreaterCost: e.value }))}     
                            {...editorOptionsSwitch}                      
                        >                            
                        </Switch>
                    </DivFormColumns>
                    <div className="dx-fieldset-header">Configuracion de facturas</div>
                    <DivFormColumns title='% de IVA' required
                        description="El iva a aplicar en las facturas, compras y movimiento de inventario">                        
                        <NumberBox                            
                            value={app.iva}                           
                            onValueChanged={e => setApp(app => ({ ...app, iva: e.value }))}
                            {...editorOptionsNumberBox}
                        >
                            <Validator>
                                <RequiredRule message="Este campo es requerido" />
                            </Validator>
                        </NumberBox>
                    </DivFormColumns>
                    <DivFormColumns title='Cliente' required
                        description="Cliente que se registra en la factura por defecto para aquellas facturas que son ventas rapidas">                      
                        <SelectBox                            
                            value={app.clientDefault}
                            disabled={true}
                            onValueChanged={e => setApp(app => ({ ...app, clientDefault: e.value }))}
                            {...editorOptionsDropDown}
                        >
                            <Validator>
                                <RequiredRule message="Este campo es requerido" />
                            </Validator>
                        </SelectBox>
                    </DivFormColumns>
                    <DivFormColumns title='% Comision' required
                        description="% de comision que ganara el ejecutivo sobre el excendete del valor de articulo">                      
                        <NumberBox                            
                            value={app.commissionPercent}                           
                            onValueChanged={e => setApp(app => ({ ...app, commissionPercent: e.value }))}
                            {...editorOptionsNumberBox}
                        >
                            <Validator>
                                <RequiredRule message="Este campo es requerido" />
                            </Validator>
                        </NumberBox>
                    </DivFormColumns>
                </div>
                <Button 
                    icon='save'
                    text={saving.text} 
                    disabled={saving.loading}
                    type="default"                     
                    useSubmitBehavior={true}>
                </Button>
            </form>         
               
        </div>
    );
}

export default AppConfig;
