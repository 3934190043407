import { _path } from "./headerNavigation";

export default [{
    layout: 'conta',
    type: 'link',
    label: 'Home',
    url: '/',
},
{
    layout: 'app',
    type: 'link',
    label: 'Facturacion',
    url: `${_path.ROOTMAIN}/facturas`,
    children: [
        { type: 'link', label: 'Facturas', url: `${_path.ROOTMAIN}/facturas` },
        { type: 'link', label: 'Facturas rápida', url: `${_path.ROOTMAIN}/facturas-quickly` },
        { type: 'link', label: 'Recibos de caja', url: `${_path.ROOTMAIN}/recibos` },
        { type: 'link', label: 'Vendedores', url: `${_path.ROOTMAIN}/vendedores` },
        {
            type: 'link',
            label: 'Reportes',
            url: '',
            children: [
                { type: 'link', label: 'Comisiones', url: `${_path.ROOTMAIN}/facturas/reportes/comisiones` },
                { type: 'link', label: 'Consolidado de productos', url: `${_path.ROOTMAIN}/facturas/reportes/productos-consolidados` },
                { type: 'link', label: 'Facturas por cliente', url: `${_path.ROOTMAIN}/facturas/reportes/3` },
            ],
        },
    ],
},

{
    layout: 'app',
    type: 'link',
    label: 'Compras',
    url: `${_path.ROOTMAIN}/movimientos/compras`,
    children: [
        { type: 'link', label: 'Lista de compras', url: `${_path.ROOTMAIN}/movimientos/compras` },
        { type: 'link', label: 'Proveedores', url: `${_path.ROOTMAIN}/proveedores` },
        { type: 'link', label: 'Cuentas por pagar', url: `${_path.ROOTMAIN}/proveedores` },
        {
            type: 'link',
            label: 'Reportes',
            url: '',
            children: [
                { type: 'link', label: 'Compras del dia', url: `${_path.ROOTMAIN}/compras/reportes/1` },
                { type: 'link', label: 'Compras por fecha', url: `${_path.ROOTMAIN}/compras/reportes/2` },
                { type: 'link', label: 'Compras por proveedores', url: `${_path.ROOTMAIN}/compras/reportes/3` },
            ],
        },
    ],
},

{
    layout: 'app',
    type: 'link',
    label: 'Inventario',
    url: `${_path.ROOTMAIN}/productos`,
    children: [
        { type: 'link', label: 'Productos', url: `${_path.ROOTMAIN}/productos` },
        { type: 'link', label: 'Marcas', url: `${_path.ROOTMAIN}/familias` },
        { type: 'link', label: 'Modelo', url: `${_path.ROOTMAIN}/presentaciones` },
        { type: 'link', label: 'Clasificaciones', url: `${_path.ROOTMAIN}/clasificaciones` },
        { type: 'link', label: 'Unidad Medida', url: `${_path.ROOTMAIN}/unidadmedida` },
        { type: 'link', label: 'Tasa de cambio', url: `${_path.ROOTMAIN}/tasa-de-cambio` },
        {
            type: 'link',
            label: 'Reportes',
            url: '',
            children: [
                { type: 'link', label: 'Kardex', url: `${_path.ROOTMAIN}/inventario/reportes/kardex` },
                { type: 'link', label: 'Existencias', url: `${_path.ROOTMAIN}/inventario/reportes/existencias` },
                { type: 'link', label: 'Stock', url: `${_path.ROOTMAIN}/inventario/reportes/stock` },
            ],
        },
    ],
},

{
    layout: 'app',
    type: 'link',
    label: 'Clientes',
    url: `${_path.ROOTMAIN}/clientes`,
    children: [
        { type: 'link', label: 'Gestion de clientes', url: `${_path.ROOTMAIN}/clientes` },
        { type: 'link', label: 'Cuentas por cobrar', url: `${_path.ROOTMAIN}/clientes/pending` },
        {
            type: 'link',
            label: 'Reportes',
            url: '',
            children: [
            ],
        },
    ],
},

{
    layout: 'app',
    type: 'link',
    label: 'Configuración',
    url: `${_path.ROOTMAIN}/usuarios`,
    children: [
        { type: 'link', label: 'Usuarios', url: `${_path.ROOTMAIN}/usuarios` },
        { type: 'link', label: 'Roles', url: `${_path.ROOTMAIN}/roles` },
        { type: 'link', label: 'Recursos', url: `${_path.ROOTMAIN}/permisos` },
        { type: 'link', label: 'Areas', url: `${_path.ROOTMAIN}/areas` },       
    ],
},

{
    layout: 'app',
    type: 'link',
    label: 'Cuenta',
    url: `${_path.ROOTMAIN}/usuarios`,
    children: [
        { type: 'link', label: 'Password', url: `/account/password` },
        { type: 'link', label: 'Salir', url: `/account/logout` },
    ],
},

];