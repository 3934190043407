export const editorOptions = { stylingMode: "filled" };

export const editorOptionsSelect = {
    valueExpr: "id",
    displayExpr: "name",
    searchEnabled: true
}

export const editorOptionsSwitch = {
    switchedOffText:"NO",
    switchedOnText:"SI",
}

export const editorOptionsNumberBox={
    showSpinButtons:true,
    showClearButton:true
}

export const editorOptsTextBoxPhone={
    mask: "0000-0000"
}

export const editorOptsTextBox={
    showClearButton:true
}

export const cssClasses = {
    0 : '',
    1 : 'custome-active',
    2 : 'custome-noactive',
}

export const formatDateTime = 'dd/MM/yyyy hh:mm a';
export const formatDate = 'dd/MM/yyyy';

export const areaRestrict = {
    bodega : 1,   

}

export const resources = {    
    facturacion : 1,   
    inventario : 2,
    ajuste : 3,
    requisa : 4,
    cotizacion : 5,
    compra : 6,
    administracion : 7,
    usuario : 8,
    client : 9,
    recibo : 10,
    app : 11,
    
}


export const dataAccess = {
    none : 0x0,
    access : 0x01,
    create : 0x02,
    update : 0x04,
    delete : 0x08
}