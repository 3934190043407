import React, { useEffect, useRef, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, EmptyItem, StringLengthRule, RequiredRule, EmailRule, AsyncRule } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { dialogProduct } from '../../store/product/productDialogReducer';
import { createStoreLocal } from '../../utils/proxy';
import { editorOptionsNumberBox, editorOptionsSelect, editorOptionsSwitch, editorOptsTextBox } from '../../data/app';
import { Button } from 'devextreme-react/button';
import http from '../../utils/http';
import uri from '../../utils/uri';
import notify from 'devextreme/ui/notify';
import { productDefault } from '../../data/defaultObjects';
import { formatId } from '../../utils/common';

const Nuevo = props => {

    let refProduct = useRef();

    const [product, setProduct] = useState({ ...productDefault });
    const [saving, setSaving] = useState(false);
    //const [stockInherited, setStockInherited] = useState(false);
    const dispatch = useDispatch();
    const { open, id } = useSelector(store => store.productDialog);

    const closeDialog = (load) => {

        refProduct.current.instance.resetValues();
        setProduct({ ...productDefault });

        dispatch(dialogProduct({ open: false, id: 0 }));

        if (load) {

            let { onSave } = props;
            onSave();

        }
    }

    const onHiding = ({ load }) => {
       
        closeDialog(load);

    }

    const guardarProduct = (e) => {
        
        let resultValidate = refProduct.current.instance.validate();

        resultValidate.complete.then(result => {
            if (result.isValid) {

                setSaving(true);

                http(uri.products.insert).asPost({ ...product })
                    .then(resp => {
                        setSaving(false);
                        notify(`Producto creado correctamente`);
                        closeDialog(true);
                    })
                    .catch(err => {
                        setSaving(false);
                        notify(err, 'error');
                    })
            }
        });
    }

    useEffect(() => {

        if (id > 0) {
            http(uri.products.getById(id)).asGet()
                .then(resp => {
                    setProduct({ ...resp });
                })
        }

    }, [id]);

    const validationCallback = (e) => {
        return new Promise(resolve => {
            resolve(
                (product.convertProductId > 0 && (product.convertProductQuantity != '' && product.convertProductQuantity != null)) 
            ||  ((product.convertProductId == '' ||  product.convertProductId == 0 ||  product.convertProductId == null) 
                    && (product.convertProductQuantity == '' ||  product.convertProductQuantity == 0 ||  product.convertProductQuantity == null)));
        });
    }

    const isNew = id == 0;

    const active = true;

    return (
        <div>
            <Popup
                width={700}
                height={450}
                title={isNew ? `Nuevo producto` : `Producto #${formatId(id)}`}
                onHiding={onHiding}
                visible={open}
                className="bg-fbfbfb"
            >
                <Form formData={product} ref={refProduct}>
                    <GroupItem colCount={2}>
                        {/* <SimpleItem>
                            <Barcode value={product.id} height={30} lineColor={"#000000"} displayValue={false}/>
                        </SimpleItem> */}
                        <SimpleItem dataField="id" colSpan={1} editorType="dxNumberBox" editorOptions={{
                            disabled: true
                        }}>
                            <Label text="Codigo" />
                        </SimpleItem>
                        <SimpleItem dataField="internalCode" colSpan={1} editorType="dxTextBox" editorOptions={{
                            ...editorOptsTextBox,
                            disabled: false
                        }}>
                            <Label text="Codigo interno" />
                        </SimpleItem>
                        <SimpleItem dataField="name" colSpan={1}>
                            <Label text="Nombre" />
                            <RequiredRule message="Ingrese el nombre" />
                            <StringLengthRule max={50} min={2} message="Máximo de caracteres 50 y 2 mínimo" />
                        </SimpleItem>
                        <SimpleItem dataField="clasificationId" editorType="dxSelectBox" colSpan={1}
                            editorOptions={{
                                dataSource: createStoreLocal({ name: 'clasification', active }),
                                ...editorOptionsSelect
                            }} >
                            <Label text="Clasification" />
                            <RequiredRule message="Seleccione la familia" />
                        </SimpleItem>
                        <SimpleItem dataField="description" colSpan={2}>
                            <Label text="Nota" />
                            <StringLengthRule max={150} message="Máximo de caracteres 150" />
                        </SimpleItem>
                        <SimpleItem dataField="familyId" editorType="dxSelectBox"
                            editorOptions={{
                                dataSource: createStoreLocal({ name: 'family', active }),
                                ...editorOptionsSelect
                            }} >
                            <Label text="Marca" />
                            <RequiredRule message="Seleccione la familia" />
                        </SimpleItem>
                        <SimpleItem dataField="presentationId" editorType="dxSelectBox"
                            editorOptions={{
                                dataSource: createStoreLocal({ name: 'presentation', active }),
                                ...editorOptionsSelect
                            }} >
                            <Label text="Modelo" />
                            <RequiredRule message="Seleccione la presentacion" />
                        </SimpleItem>
                        <SimpleItem dataField="unitOfMeasureId" editorType="dxSelectBox"
                            editorOptions={{
                                dataSource: createStoreLocal({ name: 'unitOfMeasure', active }),
                                ...editorOptionsSelect
                            }} >
                            <Label text="Unidad Med." />
                            <RequiredRule message="Seleccione la unida medida" />
                        </SimpleItem>
                        <SimpleItem dataField="stateId" editorType="dxSelectBox"
                            editorOptions={{
                                dataSource: createStoreLocal({ name: 'productState', active }),
                                ...editorOptionsSelect
                            }} >
                            <Label text="Estado" />
                            <RequiredRule message="Seleccione el estado" />
                        </SimpleItem>
                        <SimpleItem dataField="hasIva" editorType="dxSwitch"
                            editorOptions={editorOptionsSwitch}>
                            <Label text="Aplica IVA" />
                        </SimpleItem>
                        <SimpleItem dataField="stock" editorType="dxNumberBox">
                            <Label text="Stock" />
                            <RequiredRule message="ingrese el stock" />
                        </SimpleItem>
                        <SimpleItem dataField="convertProductId" editorType="dxSelectBox"
                            editorOptions={{
                                dataSource: createStoreLocal({ name: 'producsts', url: `catalogos/products/${product.id}` }),
                                ...editorOptionsSelect
                            }} >
                            <Label text="Conversion" />
                        </SimpleItem>
                        <SimpleItem dataField="convertProductQuantity" editorType="dxNumberBox" editorOptions={{
                            ...editorOptionsNumberBox
                        }}>
                            <Label text="Cantidad" />
                            <AsyncRule message="Ingrese la cantidad a convertir" validationCallback={validationCallback}></AsyncRule>
                        </SimpleItem>
                    </GroupItem>
                </Form>
                <br />
                <Button
                    width={120}
                    text={`${saving ? 'Guardando...' : 'Guardar'}`}
                    type="default"
                    icon="save"
                    stylingMode="contained"
                    className="m-1"
                    disabled={saving}
                    onClick={guardarProduct}
                />
            </Popup>
        </div>
    );
}

export default Nuevo;
