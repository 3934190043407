import React, { useRef } from 'react';
import { DataGrid } from 'devextreme-react';
import { Item } from 'devextreme-react/form';
import { 
    Paging, 
    Pager, 
    FilterRow, 
    HeaderFilter, 
    ColumnChooser, 
    Column, 
    Lookup,
    Export, 
    Editing,
    Popup,     
    Form, 
    RequiredRule,
    StringLengthRule} from 'devextreme-react/data-grid';

import uri from '../../utils/uri';
import { store } from '../../services/store';
import Title from '../../components/shared/Title';
import BlockHeader from '../../components/shared/BlockHeader';
import onExporting from '../../components/grids/Importer';
import { editorOptionsSwitch } from '../../data/app';

function Vendors(props) {

    let dataGrid = useRef();    

    const onToolbarPreparing = (e) => {  
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Agregar vendedor' ,
                icon:'plus',
                type:'default',
                stylingMode:"outlined",
                onClick: () =>  dataGrid.current.instance.addRow()
            }
        },{
            location: 'after',
            widget: 'dxButton',
            options: {
                text: 'Exportar a excel',
                icon:'xlsxfile',
                type:'success',
                stylingMode:"outlined",
                onClick: () =>  dataGrid.current.instance.exportToExcel(false)
            }
        });
    }

    const onInitNewRow = (e) => {
        e.data.active = true;
    }

    const title = 'Vendedores';
    
    return (
        <div className="container">
            <Title title={title}/>
            <BlockHeader title={title}/>          
            <DataGrid id="gridContainer"
                ref={dataGrid}
                selection={{ mode: 'single' }}
                dataSource={store({uri : uri.vendors})}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                rowAlternationEnabled={true}
                hoverStateEnabled={true}
                onToolbarPreparing={onToolbarPreparing}
                onInitNewRow={onInitNewRow}
                onExporting={(e) => onExporting(e, title)}
            >
                <Paging defaultPageSize={20} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <Export enabled={false} fileName={title} allowExportSelectedData={true} />
                <Column dataField="fullName"caption="Nombre"  width={300} />
                <Column dataField="phoneNumber" caption="Telefono" width={150} />
                <Column dataField="cellNumber"  caption="Celular" width={150} />
                <Column dataField="email" />
                <Column dataField="address" caption="Direccion"/>
                <Column dataField="observation" visible={false} caption="Observacion"/>
                <Column dataField="active" width={100} caption="Activo">
                </Column>  
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowDeleting={true}
                    useIcons={true}
                >
                    <Popup title={title} showTitle={true} width={850} height={420}>
                        
                    </Popup>
                    <Form>
                        <Item  dataField="fullName" editorOptions={{ width:300 }} >
                            <RequiredRule message="El nombre es requerido"/>
                            <StringLengthRule max={100} min={2} message="Máximo de caracteres 100 y 2 mínimo"/>
                        </Item>
                        <Item  dataField="phoneNumber" editorOptions={{ width:300 }} >
                            <StringLengthRule max={15} min={0} message="Máximo de caracteres 15 y 2 mínimo"/>
                        </Item>                    
                        <Item  dataField="cellNumber" editorOptions={{ width:300 }} >
                            <StringLengthRule max={15} min={0} message="Máximo de caracteres 15 y 2 mínimo"/>
                        </Item>                    
                        <Item  dataField="email" editorOptions={{ width:300 }} >
                            <StringLengthRule max={50} min={0} message="Máximo de caracteres 50 y 2 mínimo"/>
                        </Item>      
                        <Item  dataField="address" colSpan={2} editorType="dxTextArea" editorOptions={{ height: 50 }} >
                            <RequiredRule message="La direccion es requerida"/>
                            <StringLengthRule max={150} message="Máximo de caracteres 150"/>
                        </Item>                 
                        <Item  dataField="observation" colSpan={2} editorType="dxTextArea" editorOptions={{ height: 50 }} >
                            <StringLengthRule max={250} min={0} message="Máximo de caracteres 250 y 2 mínimo"/>
                        </Item>   
                        <Item  dataField="active" colSpan={2} editorType="dxSwitch"
                            editorOptions={editorOptionsSwitch}  >
                        </Item>                  
                    </Form>
                </Editing>
            </DataGrid>
        </div>
    )

};

export default Vendors;