import React, { useEffect, useState, useRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, RequiredRule, StringLengthRule, EmptyItem } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { billDialogAction } from '../../store/bill/billDialogReducer';
import { createStoreLocal } from '../../utils/proxy';
import { editorOptionsSelect, formatDate } from '../../data/app';
import { DataGrid, Button } from 'devextreme-react';
import { Column, Editing, Lookup, RequiredRule as RuleRequired, Button as ButtonGrid, Summary, TotalItem } from 'devextreme-react/data-grid';
import { cellRender, formatToMoney, formatId } from '../../utils/common';
import http from '../../utils/http';
import notify from 'devextreme/ui/notify';
import { billDefault } from '../../data/defaultObjects';
import { paymentType } from '../../data/enums';
import { editorOptionsDropDown } from '../../components/dropdown/ClienteDropDown';
import ProductDDBComponent from '../../components/dropdown/ProductDDBComponent';
import useProducts from '../../hooks/useProducts';
import uri from '../../utils/uri';
import Resumen from '../../components/form/Resumen';

const Nuevo = props => {

    const { billDialog: { open, id, clientId }, user, appInfo } = useSelector(store => store);
    const [resumenData, setResumenData] = useState([]);
    const billDefaultCustom = { ...billDefault, clientId: clientId };

    const { products, setProducts } = useProducts({ areaId: user.areaId, exists: true });
    const [bill, setBill] = useState({ ...billDefaultCustom });
    const [saving, setSaving] = useState(false);

    let refForm = useRef();
    let refGrid = useRef();

    useEffect(() => {

        if (open)
            if (appInfo.rate == 0) {
                dispatch(billDialogAction({ id: 0, clientId: 0, open: false }));
                notify('Debe ingresar la tasa de cambio del dia para poder facturar', 'warning', 5000)
                props.history.push('/app/tasa-de-cambio');
            }

        if (id > 0) {

            http(uri.products.getByArea(user.areaId)).asGet().then(data => {
                setProducts(data);

                http(uri.bills.getById(id)).asGet().then(resp => {

                    resp.billDetails.forEach(detail => {

                        let info = products.find(x => x.id == detail.productId);

                        detail['presentation'] = info.presentation;
                        detail['family'] = info.family;

                    });

                    setBill({ ...bill, ...resp });
                    setResumenData([...bill.billDetails])
                })

            });

        } else {

            setBill({ ...billDefaultCustom, billDetails: [] });
            setResumenData([])

        }

    }, [open]);

    const dispatch = useDispatch();
    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Agregar articulo',
                icon: 'plus',
                type: 'default',
                stylingMode: "outlined",
                onClick: () => refGrid.current.instance.addRow()
            }
        });
    }

    const closeDialog = (load) => {

        refForm.current.instance.resetValues();
        refGrid.current.instance.cancelEditData();

        dispatch(billDialogAction({ id: 0, clientId: 0, open: false }));
        if (load) {
            let { onSave } = props;
            onSave();
        }
    }

    const onHiding = ({ load }) => {
        closeDialog(load);
    }

    const guardarFactura = (e) => {

        refGrid.current.instance.saveEditData();
        let result = refGrid.current.instance.hasEditData();

        if (!result) {

            let result = refForm.current.instance.validate();

            if (result.isValid) {

                setSaving(true);
                let data = { ...bill };

                http(uri.bills.insert).asPost(data).then(resp => {
                    setSaving(false);
                    notify(`Factura registrada correctamente`);
                    closeDialog(true);
                }).catch(err => {
                    setSaving(false);
                    notify(err, 'error', 5000);
                });

            }
        }

    }

    const setCellValue = (prop, newData, value, currentRowData) => {

        newData[prop] = value || 0;
        if (prop == 'productId' && value) {

            let info = products.find(x => x.id == value);

            newData['presentation'] = info.presentation;
            newData['family'] = info.family;
            newData['price'] = info.price;
            newData['customPrice'] = info.price;
            newData['cost'] = info.cost;
            !currentRowData['quantity'] && (newData['quantity'] = 1);
            !currentRowData['total'] && (newData['total'] = info.price);
        }

        if (prop == 'quantity' && (+value) >= 0) {
            newData['total'] = currentRowData['price'] * value;
        }

        if (prop == 'customPrice' && (+value) >= 0) {
            newData['total'] = currentRowData['quantity'] * value;
        }

    }

    const onCellPrepared = e => {

        const cellsQuantity = ['quantity', 'quantityRequest']

        if (e.rowType == 'data' && e.column.allowEditing) {
            if (cellsQuantity.includes(e.column.dataField))
                e.cellElement.classList.add('quantity-text');
            if (e.column.dataField == 'customPrice')
                e.cellElement.classList.add('customPrice-text');

        }

    }

    const onRowInserted = e => {
        setResumenData([...bill.billDetails])
    }

    const isNew = id == 0;
    const active = id == 0;

    return (
        <div>
            <Popup
                width={1050}
                height={650}
                title={isNew ? `Nueva factura` : `Ver factura ${formatId(id)}`}
                onHiding={onHiding}
                visible={open}
                className="bg-fbfbfb"
            >
                <Form formData={bill} ref={refForm}>
                    <GroupItem colCount={3}>
                        <GroupItem colSpan={2}>
                            <GroupItem colCount={4}>

                                <SimpleItem dataField="date" colSpan={2} editorType="dxDateBox"
                                    editorOptions={{
                                        displayFormat: formatDate,
                                        openOnFieldClick: true,
                                    }} >
                                    <Label text="Fecha" />
                                    <RequiredRule message="Seleccione la fecha" />
                                </SimpleItem>
                                <SimpleItem dataField="paymentTypeId" colSpan={2} editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: createStoreLocal({ name: 'billPaymentType' }),
                                        ...editorOptionsSelect,
                                        onValueChanged: e => setBill({ ...bill, paymentTypeId: e.value, creditDays: e.value == paymentType.contado ? 0 : 30 })
                                    }} >
                                    <Label text="Tipo pago" />
                                    <RequiredRule message="Seleccione el tipo" />
                                </SimpleItem>
                                <SimpleItem dataField="clientId" colSpan={4} editorType="dxSelectBox"
                                    editorOptions={editorOptionsDropDown} >
                                    <Label text="Cliente" />
                                    <RequiredRule message="Seleccione el cliente" />
                                </SimpleItem>
                                <SimpleItem dataField="creditDays" colSpan={2} editorType="dxNumberBox"
                                    editorOptions={{
                                        disabled: true
                                    }} >
                                    <Label text="Dias crédito" />
                                    <RequiredRule message="Seleccione el tipo" />
                                </SimpleItem>
                                <SimpleItem dataField="reference" colSpan={2} editorType="dxTextBox"
                                    editorOptions={{ placeholder: 'Factura No', showClearButton: true }} >
                                    <Label text="Referencia" />
                                    <RequiredRule message="La referencia es requerida" />
                                </SimpleItem>
                                <SimpleItem dataField="vendorId" colSpan={4} editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: createStoreLocal({ name: 'vendor', active }),
                                        valueExpr: "id",
                                        displayExpr: "fullName",
                                        searchEnabled: true,
                                    }} >
                                    <Label text="Vendedor" />
                                </SimpleItem>

                                <SimpleItem dataField="observation" colSpan={4} editorType="dxTextBox">
                                    <Label text="Observacion" />
                                    <StringLengthRule max={150} message="Maximo 150 caracteres" />
                                </SimpleItem>

                            </GroupItem>
                        </GroupItem>
                        <GroupItem colSpan={1}>
                            <Resumen data={resumenData} />
                        </GroupItem>

                    </GroupItem>
                    <GroupItem>
                        <DataGrid id="gridDetails"
                            ref={refGrid}
                            selection={{ mode: 'single' }}
                            dataSource={bill.billDetails}
                            showBorders={true}
                            showRowLines={true}
                            allowColumnResizing={true}
                            allowColumnReordering={true}
                            height={290}
                            onToolbarPreparing={onToolbarPreparing}
                            onRowInserted={onRowInserted}
                            onRowRemoved={onRowInserted}
                            onRowUpdated={onRowInserted}
                            onCellPrepared={onCellPrepared}
                        >
                            <Column dataField="productId" caption="Producto"
                                setCellValue={setCellValue.bind(null, "productId")}
                                editCellComponent={props => <ProductDDBComponent showPrice={true} {...props} />}
                            >
                                <Lookup
                                    dataSource={products}
                                    valueExpr="id"
                                    displayExpr={item => item ? `${item.id} - ${item.name}` : ''}

                                />
                                <RuleRequired />
                            </Column>
                            <Column dataField="family" caption="Marca" width={120} allowEditing={false}>
                                <RuleRequired />
                            </Column>
                            <Column dataField="presentation" caption="Modelo" width={120} allowEditing={false}>
                                <RuleRequired />
                            </Column>
                            <Column dataField="quantity" caption="Cantidad" dataType="number" width={80}
                                setCellValue={setCellValue.bind(null, "quantity")}
                            >
                                <RuleRequired />
                            </Column>
                            <Column visible={false} dataField="price" caption="Precio" dataType="number" width={100} allowEditing={false} cellRender={cellRender()} >
                                <RuleRequired />
                            </Column>
                            <Column dataField="customPrice" caption="Precio" dataType="number" width={100} cellRender={cellRender()}
                                setCellValue={setCellValue.bind(null, "customPrice")}>
                                <RuleRequired />
                            </Column>
                            <Column dataField="total" caption="Total" dataType="number" width={120} allowEditing={false} cellRender={cellRender()} >
                                <RuleRequired />
                            </Column>
                            <Column type="buttons" width={50}>
                                <ButtonGrid name="delete" />
                            </Column>
                            <Editing
                                mode="cell"
                                allowDeleting={true}
                                allowUpdating={true}
                                selectTextOnEditStart={true}
                                useIcons={true}
                            ></Editing>
                        </DataGrid>
                    </GroupItem>
                </Form>
                <br />
                <Button
                    className="mt-10"
                    text={saving ? 'Guardando...' : `Guardar factura`}
                    type="success"
                    icon='save'
                    disabled={saving}
                    visible={isNew}
                    onClick={guardarFactura}
                />

            </Popup>
        </div>
    );
}

export default Nuevo;
