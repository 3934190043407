import React,{ useRef } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    Editing,
    Export,
    FilterRow,
    HeaderFilter,
    Lookup,
    Pager,
    Paging,      
    Button as ButtonGrid 
} from 'devextreme-react/data-grid';
import BlockHeader from '../../../components/shared/BlockHeader';
import Title from '../../../components/shared/Title';
import uri from '../../../utils/uri';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../services/store';
import { createStore } from '../../../utils/proxy';
import Nuevo from './Nuevo';
import { dialogAreaProduct } from '../../../store/areaProduct/areaProductDialogReducer';
import onExporting from '../../../components/grids/Importer';
import { dataFormatId } from '../../../utils/common';


const Stock = () => {

    const { areaId } = useSelector(store => store.user);

    const dataGrid = useRef();
    const dispatch = useDispatch();

    const openDialog = (e) => {

        const { productId, product } = e.row.data;
        dispatch(dialogAreaProduct({open : true, productId, name : product.name}));
    } 
        

    const onCellPrepared = e => {
        
        if (e.rowType == 'data') {    
            console.log(e.data);        
            if(e.column.dataField == "stock" && e.data.stockMin)
                if(e.data.stock < e.data.stockMin)
                    e.cellElement.classList.add('txt-stockMin');  
                else
                    e.cellElement.classList.add('txt-stock');  

        }

    }

    const cb = data => data.map(x => {
        x.isLimit = x.stockMin ? x.stock <= x.stockMin : false
        return x;
    });

    const onSave = () => dataGrid.current.instance.refresh();

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Exportar a excel',
                icon: 'xlsxfile',
                type: 'success',
                stylingMode: "outlined",
                onClick: () => dataGrid.current.instance.exportToExcel(false)
            }
        });
    }
    

    const title = 'Stock';

    return (
        <div className="container">
            <Title title={title} />
            <BlockHeader title={title} >
            </BlockHeader>     
            <Nuevo onSave={onSave} areaId={areaId}/>       
            <DataGrid id="gridContainer"
                ref={dataGrid}
                selection={{ mode: 'single' }}
                dataSource={store({ uri: uri.areaProducts(areaId), cb  })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                hoverStateEnabled={true}
                onCellPrepared={onCellPrepared}
                onToolbarPreparing={onToolbarPreparing}
                onExporting={(e) => onExporting(e, title)}
            >
                <Paging defaultPageSize={20} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                />
                <Export enabled={false} fileName={title} allowExportSelectedData={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <Column dataField="areaId" visible={false} />
                <Column dataField="product.id" caption='Codigo'  width={100} cellRender={dataFormatId}/>
                <Column dataField="product.name" caption='Nombre' />
                <Column dataField="product.familyId" caption="Marca" width={150}>
                    <Lookup disabled={true} dataSource={createStore({ name: 'family' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="product.presentationId" caption="Modelo" width={150}>
                    <Lookup disabled={true} dataSource={createStore({ name: 'Presentation' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="product.unitOfMeasureId" caption="UM" width={120}>
                    <Lookup disabled={true} dataSource={createStore({ name: 'UnitOfMeasure' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="stock" caption='Existencias' width={100}/>
                <Column dataField="inherit" caption='Heredado' width={100} allowHeaderFiltering={false}/>
                <Column dataField="stockMin" caption="Mínimo" width={100} allowHeaderFiltering={false}/>
                <Column dataField="stockMax" caption="Máximo" width={100} allowHeaderFiltering={false}/>
                <Column dataField="isLimit" caption="Reabastecer ?" allowHeaderFiltering={false}/>
                <Column dataField="discount" caption="% Descuento" format="#0%" width={100} allowHeaderFiltering={false}/>
                <Column type="buttons" width={60}>
                    <ButtonGrid name="edit" onClick={e => openDialog(e)}/>
                </Column>
                <Editing
                    mode="popup"
                    allo
                    useIcons={true}
                    allowUpdating={true}
                >                    
                </Editing>
            </DataGrid>
        </div>
    );
}

export default Stock;
