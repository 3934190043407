import React from 'react';
import { inPutProductTypes } from '../../../data/enums';
import InPutProducts from '../inPutProducts/InPutProducts';
import { resources } from '../../../data/app';
import NuevoPurchase from './NuevoPurchase';
import { dialogPurchase } from '../../../store/inPutProductPurchase/purchaseDialogReducer';

const initPurchases = () => {

    const title ='Compras';
    const btnAddText ='Nueva Compra';
    const typeId = inPutProductTypes.compra;

    return (
        <InPutProducts 
            title={title} 
            btnAddText={btnAddText} 
            typeId={typeId} icon="dx-icon-cart color-icon-green" 
            resourcesId = {resources.compra}
            Component={NuevoPurchase}
            dialog = {dialogPurchase}/>
    );
}

export default initPurchases;
