import React from 'react';

const Cardinfo = ({ title, icon, value, percent }) => {
    return (
        <div className='m15 dash-color'>
            <div className='dash-col p-10 mrml10'>
                <div className="card-row">
                    <div>
                        <div className="card-info-title">
                            {title}
                        </div>
                        <div className="card-info-value pt-10">
                            {value}
                        </div>
                    </div>
                    <div>
                        <div className='dash-icon'>
                            <i className={`dx-icon-${icon} fs20`}></i>
                        </div>
                    </div>
                </div>
                <div className="pt-10">
                    <div className='dash-footer'>
                        <span className='dash-percent'>{percent}</span>
                        <span className='dash-dateinfo'>Ultimo mes</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cardinfo;
