export const maleAgeData = [
    { month: 'ene', credit: 45000, cash: 54000 },
    { month: 'feb', credit: 42000, cash: 25000 },
    { month: 'mar', credit: 54000, cash: 22000 },
    { month: 'abr', credit: 65000, cash: 36000 },
    { month: 'may', credit: 38000, cash: 39000 },
    { month: 'jun', credit: 64000, cash: 52000 },
    { month: 'jul', credit: 31000, cash: 48000 },
    { month: 'ago', credit: 23000, cash: 31000 },
    { month: 'sep', credit: 18000, cash: 13000 },
    { month: 'oct', credit: 19000, cash: 16000 },
    { month: 'nov', credit: 34000, cash: 29000 },
    { month: 'dic', credit: 32000, cash: 14000 }
];

export const populationByRegions = [{

    region: 'Electronicos',
    val: 4119626293,
}, {
    region: 'Repuestos',
    val: 7270822221,
}, {
    region: 'Servicios',
    val: 3510475630,
}];

export const energySources = [
    { value: 'electrodomesticos', name: 'Electrodomesticos' },
    { value: 'repuestos', name: 'Accesorios' },
    { value: 'servicios', name: 'Servicios' }
];



export const countriesInfo = [{
    country: 'ene',
    electrodomesticos: 59.8,
    repuestos: 937.6,
    servicios: 582
}, {
    country: 'feb',
    electrodomesticos: 74.2,
    repuestos: 308.6,
    servicios: 35.1
}, {
    country: 'mar',
    electrodomesticos: 40,
    repuestos: 128.5,
    servicios: 361.8
}, {
    country: 'abr',
    electrodomesticos: 35.3,
    repuestos: 819.8,
    servicios: 398.9
}, {

    country: 'may',
    electrodomesticos: 18.8,
    repuestos: 906.4,
    servicios: 459.1
}, {
    country: 'jun',
    electrodomesticos: 20.3,
    repuestos: 198.5,
    servicios: 586.7
}



]