import React from 'react';
import { Grid, ArgumentAxis,
    Chart, Series, CommonSeriesSettings, Legend, ValueAxis, Label, Export, Tooltip, Title, Subtitle, Margin
} from 'devextreme-react/chart';
import PieChart, {
    Legend as PieLegend,
    Series as PieSeries,
    Tooltip as PieTooltip,
    Format as PieFormat,
    Label as PieLabel,
    Connector,
    Export as PieExport,
} from 'devextreme-react/pie-chart';

import { countriesInfo, energySources, maleAgeData, populationByRegions } from '../../data/dashboard';
import Cardinfo from './CardInfo';
import './index.css';

const Dashboard = () => {

    const customizeTooltip = (arg) => {
        return { text: `${arg.seriesName} total: ${arg.valueText}` };
    }

    const customizeTooltip2 = arg => {
        return { text: `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`, }
    }

    return (
        <div>
            <div className='dash-row'>
                <div className='dash-col-6'>
                    <div className='dash-row'>
                        <div className='dash-col-6'>
                            <Cardinfo title={'Ventas Año'} icon={'group'} value={'36,254'} percent={'5.27%'} />
                        </div>
                        <div className='dash-col-6'>
                            <Cardinfo title={'Ventas Mes'} icon={'cart'} value={'5,543'} percent={'1.08%'} />
                        </div>
                    </div>
                    <div className='dash-row'>
                        <div className='dash-col-6'>
                            <Cardinfo title={'Facturas Vencidas'} icon={'money'} value={'6,254'} percent={'7.00%'} />
                        </div>
                        <div className='dash-col-6'>
                            <Cardinfo title={'Facturas Pagadas'} icon={'chart'} value={'+30.65%'} percent={'4.87%'} />
                        </div>
                    </div>
                </div>
                <div className='dash-col-6'>
                    <div className="m15 dash-color">
                        <Chart
                            id="chart"
                            title="Ventas mensuales"
                            dataSource={maleAgeData}
                            className='w-100'
                        >
                            <CommonSeriesSettings argumentField="month" type="stackedBar" barWidth={10} />
                            <Series
                                color={'#727cf5'}
                                valueField="credit"
                                name="Credito"
                            />
                            <Series
                                color={'#efefef'}
                                valueField="cash"
                                name="Contado"
                            />

                            <ValueAxis position="left">
                            </ValueAxis>
                            <Legend
                                verticalAlignment="bottom"
                                horizontalAlignment="center"
                                itemTextPosition="top"
                            />
                            <Export enabled={true} />
                            <Tooltip
                                enabled={true}
                                location="edge"
                                customizeTooltip={customizeTooltip}
                            />
                        </Chart>
                    </div>
                </div>
            </div>
            <div className='dash-row'>
                <div className='dash-col-6'>
                    <div className='m15 dash-color'>
                        <PieChart
                            id="pie"
                            type="doughnut"
                            title="Peso por categoria de producto"
                            palette="Soft Pastel"
                            dataSource={populationByRegions}
                        >
                            <PieSeries argumentField="region">
                                <PieLabel visible={true} forma="millions">    <Connector visible={true} />
                                </PieLabel>
                            </PieSeries>
                            <PieExport enabled={true} />
                            <PieLegend
                                margin={0}
                                horizontalAlignment="right"
                                verticalAlignment="top"
                            />
                            <PieTooltip enabled={true} customizeTooltip={customizeTooltip2}>
                                <PieFormat type="millions" />
                            </PieTooltip>
                        </PieChart>
                    </div>
                </div>
                <div className='dash-col-6'>
                <div className='m15 dash-color'>
                    <Chart
                        palette="Soft Pastel"
                        dataSource={countriesInfo}
                    >
                        <CommonSeriesSettings
                            argumentField="country"
                            type={'line'}
                        />
                        {
                            energySources.map((item) => <Series
                              
                                key={item.value}
                                valueField={item.value}
                                name={item.name} />)

                        }

                        <Margin bottom={20} />
                        <ArgumentAxis
                            valueMarginsEnabled={false}
                            discreteAxisDivisionMode="crossLabels"

                        >    <Grid visible={true} />
                        </ArgumentAxis>
                        <Legend
                            verticalAlignment="bottom"
                            horizontalAlignment="center"
                            itemTextPosition="bottom"
                        />

                        <Export enabled={true} />
                        <Title text="Ventas por categorias">
                            <Subtitle text="(Millions of Tons, Oil Equivalent)" />
                        </Title>
                        <Tooltip enabled={true} />
                    </Chart>
                </div>
            </div >
            </div >
        </div >
    );
}

export default Dashboard;
