import React, { useEffect, useRef, useState } from "react";
import {
    Paging,
    Pager,
    FilterRow,
    ColumnChooser,
    Column,
    Lookup,
    Export,   
    Button as ButtonGrid,   
    Editing,
    Popup,     
    Form, 
    RequiredRule,
    StringLengthRule, 
}
    from 'devextreme-react/data-grid';
   
import { EmailRule, EmptyItem, Item } from 'devextreme-react/form';
import { DataGrid } from 'devextreme-react';
import BlockHeader from '../../components/shared/BlockHeader';
import Title from "../../components/shared/Title";
import { store } from "../../services/store";
import { dataAccess, editorOptionsNumberBox, editorOptionsSwitch, formatDate, resources, editorOptsTextBoxPhone } from '../../data/app';
import { createStore, createStoreLocal } from "../../utils/proxy";
import uri from "../../utils/uri";
import useAuthorization from "../../hooks/useAuthorization";
import { cellRender } from "../../utils/common";
import http from "../../utils/http";

const Clients = props => {
   
    const { history } = props;

    const [cities, setCities] = useState([]);

    const { authorized } = useAuthorization([resources.client, dataAccess.access ]);

    let dataGrid = useRef();
    const title = "Clientes";        

    const onToolbarPreparing = e => {

        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                stylingMode:"outlined",
                type:'default',
                icon: 'add',
                text: 'Agregar cliente',
                onClick: e => dataGrid.current.instance.addRow()// dispatch(openDialog({open : true, id : 0}))
            }
        });
    }

    const onRowDblClick = (e) => {
        history.push('/clinica/cliente/'+e.data.id);
    }

    const onSave = (params) => {
        dataGrid.current.instance.refresh();
    }    

    const getFilteredCities = (options) => {
        return {
          store: cities,
          filter: options.data ? ['departmentId', '=', options.data.departmentId] : null
        };
      }

    const setStateValue = (rowData, value) => {

        rowData['departmentId'] = value || 0;
        rowData.cityId = null;
        
    }

    useEffect(() => {
        http('catalogos/City').asGet().then(resp => setCities(resp))
    }, [0]);

    const onInitNewRow = (e) => {
        e.data.creditLimit = 0;
    }


    return authorized(
        <div className="container">
            <Title title={title} />
            <BlockHeader title={title} />           
            <DataGrid
                ref={dataGrid}
                dataSource={store({uri : uri.clients, remoteOperations: true })}
                selection={{ mode: 'single' }}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                columnHidingEnabled={true}
                allowColumnReordering={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}
                rowAlternationEnabled={true}
                onToolbarPreparing={onToolbarPreparing}
                onRowDblClick={onRowDblClick}
                onInitNewRow={onInitNewRow}
                remoteOperations={{
                    paging: true,
                    filtering: true
                }}               
            >
                <Pager allowedPageSizes={[10, 15, 30, 50]} showPageSizeSelector={true} showInfo={true} />
                <Paging defaultPageSize={15} />
                <FilterRow visible={true} />
                <ColumnChooser enabled={true} />
                <Export enabled={true} fileName={title} allowExportSelectedData={true} />                     
                <Column dataField="identification" caption="Identificacion" width={130} />
                <Column dataField="name" caption="Nombre" minWidth={180} />
                <Column dataField="lastName" caption="Apellidos" minWidth={140}/>
                <Column dataField="birthdate" caption="Fecha de Nac" width={120} dataType="date" format={formatDate} />
                <Column dataField="sexId" caption="Sexo" visible={false}>
                    <Lookup disabled={true} dataSource={createStoreLocal({name : 'sex'} )} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="address" caption="Dirrecion" visible={false} allowFiltering={false} />
                <Column dataField="phoneNumber" caption="Telefono" allowFiltering={false} />
                <Column dataField="phoneNumberWork"  caption="Telefono Trab" allowFiltering={false}  visible={false}/>
                <Column dataField="cellNumber" caption="Celular" allowFiltering={false} />
                <Column dataField="email" caption="Correo" allowFiltering={false} visible={false} />               
                <Column dataField="departmentId" caption="Departamento" width={150} caption="Departamento" setCellValue={setStateValue}>
                    <Lookup disabled={true} dataSource={createStore({name : 'department'} )} valueExpr="id" displayExpr="name" />
                </Column> 
                <Column dataField="cityId" caption="Ciudad" width={150} caption="Ciudad">
                    <Lookup disabled={true} dataSource={getFilteredCities} valueExpr="id" displayExpr="name" />
                </Column>      
                <Column dataField="creditLimit" caption="Credito" allowFiltering={false} cellRender={cellRender()} />        
                <Column dataField="isCompany" caption="Empresa" allowFiltering={false} />        
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowDeleting={true}
                    useIcons={true}
                >
                    <Popup title={title} showTitle={true} width={800} height={550}>

                    </Popup>
                    <Form>
                        <Item dataField="identification" colSpan={1}>
                            <RequiredRule message="El campo es requerido" />     
                            <StringLengthRule max={50} message="Máximo de caracteres 50" />                       
                        </Item>
                        <Item  dataField="isCompany" editorType="dxSwitch" editorOptions={editorOptionsSwitch} colSpan={1}>                           
                        </Item>   
                        <Item dataField="name" colSpan={1}>
                            <RequiredRule message="El campo es requerido" />     
                            <StringLengthRule max={100} message="Máximo de caracteres 100" />                       
                        </Item>
                        <Item dataField="lastName" colSpan={1}>
                            <RequiredRule message="El campo es requerido" />     
                            <StringLengthRule max={100} message="Máximo de caracteres 100" />                       
                        </Item>                       
                        <Item dataField="birthdate" colSpan={1} editorOptions={{
                            openOnFieldClick:true
                        }}>                     
                        </Item>
                        <Item dataField="sexId" colSpan={1} />
                        <Item dataField="departmentId" colSpan={1}/>
                        <Item dataField="cityId" colSpan={1}/>                        
                        <Item dataField="phoneNumber" colSpan={1} editorOptions={editorOptsTextBoxPhone}>
                            <StringLengthRule max={50} message="Máximo de caracteres 50" />
                        </Item>
                        <Item dataField="phoneNumberWork" colSpan={1} editorOptions={editorOptsTextBoxPhone}>
                            <StringLengthRule max={50} message="Máximo de caracteres 50" />
                        </Item>
                        <Item dataField="cellNumber" colSpan={1} editorOptions={editorOptsTextBoxPhone}>
                            <StringLengthRule max={50} message="Máximo de caracteres 50" />
                        </Item>
                        <Item dataField="email" colSpan={1}>
                            <StringLengthRule max={50} message="Máximo de caracteres 50" />
                            <EmailRule />
                        </Item>
                        <Item  dataField="creditLimit" editorType="dxNumberBox" editorOptions={editorOptionsNumberBox} colSpan={1}>
                            <RequiredRule message="El campo es requerido" />
                        </Item>        
                        <EmptyItem />               
                        <Item  dataField="address" editorType="dxTextArea" colSpan={2}>
                            <RequiredRule message="El campo es requerido" />
                            <StringLengthRule max={150} message="Máximo de caracteres 150"/>
                        </Item>
                                            
                    </Form>
                </Editing>
            </DataGrid>
        </div>
    )


}


export default Clients;
