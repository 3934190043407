export const _path = {
    ROOTMAIN: '/app'
}

const menu = [{
    layout: 'app',
    title: 'Facturacion',
    url: `${_path.ROOTMAIN}/facturas`,
    submenu: {
        type: 'menu',
        menu: [
            { title: 'Facturas', url: `${_path.ROOTMAIN}/facturas` },
            { title: 'Facturas rápida', url: `${_path.ROOTMAIN}/facturas-quickly` },
            { title: 'Recibos de caja', url: `${_path.ROOTMAIN}/recibos` },
            { title: 'Vendedores', url: `${_path.ROOTMAIN}/vendedores` },
            { 
                title: 'Reportes', 
                url: ``,
                submenu : [
                    { title: 'Comisiones', url: `${_path.ROOTMAIN}/facturas/reportes/comisiones` },
                    { title: 'Consolidado de productos', url: `${_path.ROOTMAIN}/facturas/reportes/productos-consolidados` },               
                    { title: 'Facturas por cliente', url: `${_path.ROOTMAIN}/facturas/reportes/3` },
                    { title: 'Facturas en mora', url: `${_path.ROOTMAIN}/facturas/reportes/4` },
                ]
            },   
        ],
    },
},{
    layout: 'app',
    title: 'Inventario',
    url: `${_path.ROOTMAIN}/productos` ,
    submenu: {
        type: 'menu',
        menu: [
            { title: 'Productos', url: `${_path.ROOTMAIN}/productos` },           
            { title: 'Marcas', url: `${_path.ROOTMAIN}/familias` },
            { title: 'Modelo', url: `${_path.ROOTMAIN}/presentaciones` },
            { title: 'Clasificaciones', url: `${_path.ROOTMAIN}/clasificaciones` },
            { title: 'Unidad Medida', url: `${_path.ROOTMAIN}/unidadmedida` },          
            { title: 'Tasa de cambio', url: `${_path.ROOTMAIN}/tasa-de-cambio` },
            { 
                title: 'Reportes', 
                url: ``,
                submenu : [
                    { title: 'Kardex', url: `${_path.ROOTMAIN}/inventario/reportes/kardex` },
                    { title: 'Existencias', url: `${_path.ROOTMAIN}/inventario/reportes/existencias` },
                    { title: 'Stock', url: `${_path.ROOTMAIN}/inventario/reportes/stock` },
                ]
            },   
        ],
    }
},{
    layout: 'app',
    title: 'Clientes',
    url: `${_path.ROOTMAIN}/clientes` ,
    submenu: {
        type: 'menu',
        menu: [
            { title: 'Gestion de clientes', url: `${_path.ROOTMAIN}/clientes` },           
            { title: 'Cuentas por cobrar', url: `${_path.ROOTMAIN}/clientes/pending` },
            { 
                title: 'Reportes', 
                url: ``,
                submenu : [
                    // { title: 'Clientes', url: `${_path.ROOTMAIN}/inventario/reportes/kardex` },
                    // { title: 'Existencias', url: `${_path.ROOTMAIN}/inventario/reportes/existencias` },
                ]
            },   
        ],
    },
},{
    layout: 'app',
    title: 'Compras',
    url: `${_path.ROOTMAIN}/movimientos/compras` ,
    submenu: {
        type: 'menu',
        menu: [
            { title: 'Lista de compras', url: `${_path.ROOTMAIN}/movimientos/compras` },           
            { title: 'Proveedores', url: `${_path.ROOTMAIN}/proveedores` },
            { title: 'Cuentas por pagar', url: `${_path.ROOTMAIN}/proveedores` },
            { 
                title: 'Reportes', 
                url: ``,
                submenu : [
                    { title: 'Compras del dia', url: `${_path.ROOTMAIN}/compras/reportes/1` },
                    { title: 'Compras por fecha', url: `${_path.ROOTMAIN}/compras/reportes/2` },
                    { title: 'Compras por proveedores', url: `${_path.ROOTMAIN}/compras/reportes/2` },
                ]
            },   
        ],
    },
},{
    layout: 'app',
    title: 'Entradas',
    url: `${_path.ROOTMAIN}/movimientos/entradas` ,
    submenu: {
        type: 'menu',
        menu: [
            { title: 'Ajuste de entrada', url: `${_path.ROOTMAIN}/movimientos/entradas` },    
            { title: 'Solicitud de traslado', url: `${_path.ROOTMAIN}/movimientos/traslados` },    
            { title: 'Compras', url: `${_path.ROOTMAIN}/movimientos/compras` },
            { title: 'Inventario Inicial', url: `${_path.ROOTMAIN}/movimientos/inv-inicial` },
        ],
    },
},{
    layout: 'app',
    title: 'Salidas',
    url: `${_path.ROOTMAIN}/movimientos/salidas` ,
    submenu: {
        type: 'menu',
        menu: [
            { title: 'Ajuste de salida', url: `${_path.ROOTMAIN}/movimientos/salidas` },
            { title: 'Despacho', url: `${_path.ROOTMAIN}/movimientos/despacho` },    
        ],
    },
},{
    layout: 'app',
    title: 'Configuración',
    url: `${_path.ROOTMAIN}/usuarios` ,
    submenu: {
        type: 'menu',
        menu: [
            { title: 'Usuarios', url: `${_path.ROOTMAIN}/usuarios` },
            { title: 'Roles', url: `${_path.ROOTMAIN}/roles` },
            { title: 'Recursos', url: `${_path.ROOTMAIN}/permisos` },
            { title: 'Areas', url: `${_path.ROOTMAIN}/areas` },   
        ],
    },
}];


export default menu;