import moment from 'moment';

export const billDefault = {
    creditDays : 0,
    vendorId: null,
    areaId: 0,
    observaction: '',
    nameClient: '',
    clientId: null,
    rate : 0,
    date : new Date(moment().year(), moment().month(), moment().date(), 0, 0, 0, 0),
}

export const inPutProductDefault = {
    date : new Date(moment().year(), moment().month(), moment().date(), 0, 0, 0, 0),
}

export const outPutProductDefault = {
    ...inPutProductDefault
}

export const purchaseDefault = {
    areaId: 0,
    date : new Date(moment().year(), moment().month(), moment().date(), 0, 0, 0, 0),
    typeId:0,
    providerId: null,
    observaction: '',
    rate : 0,
}

export const traslateDefault ={
    date : new Date(moment().year(), moment().month(), moment().date(), 0, 0, 0, 0),
    areaId: 0,
    observaction: '',
}


export const defaultFacturasDetalle = {
    cantidad: 0,
    precio: 0,
    descuentoAverage: 0,
    importe: 0,
    inventarioId: 0,
    ivaAverage: 0.15,
    ivaMonto: 0,
    subTotal: 0,
    total: 0
}

export const receiptDefault = {
    id: 0,
    billId: 0,
    amount : 0,   
    balance : 0,   
    observation: '',    
    client: '',    
    paid :false
} 




export const productDefault = {
    id:0,
    description: "",
    familyId: null,
    hasIva: false,
    stockInherited: false,
    name: "",
    presentationId: null,
    stateId: 1,
    stock: 0,
    unitOfMeasureId: null,
    convertProductId: 0,
    convertProductQuantity: 0,
}

export const labels = {
    precio : 'Precio',
    costo : 'Costo',
}