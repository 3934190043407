
export const dialogDefault = {
    open: false,
    id: 0,
};

export const catalogsName = 'catalogs';

export const DIALOG_AREAPRODUCT = 'DIALOG_AREAPRODUCT';

export const DIALOG_INPUTPRODUCT = 'DIALOG_INPUTPRODUCT';
export const DIALOG_OUTPUTPRODUCT = 'DIALOG_OUTPUTPRODUCT';
export const DIALOG_PRODUCT = 'DIALOG_PRODUCT';


export const GET_CATALOGS = 'GET_CATALOGS';
export const DIALOG_TRANSFER = 'DIALOG_TRANSFER';
export const DIALOG_TRANSFER_WITHPRODUCT = 'DIALOG_TRANSFER_WITHPRODUCT';

export const OPEN_DIALOG_SERVICETEST = 'OPEN_DIALOG_SERVICETEST';
export const CLOSE_DIALOG_SERVICETEST = 'CLOSE_DIALOG_SERVICETEST';

export const OPEN_DIALOG = 'OPEN_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'

export const OPEN_DIALOG_RECIBO = 'OPEN_DIALOG_RECIBO';
export const CLOSE_DIALOG_RECIBO = 'CLOSE_DIALOG_RECIBO';
export const DIALOG_BILL = 'DIALOG_BILL';
export const DIALOG_PURCHASE = 'DIALOG_PURCHASE';
export const DIALOG_TRASLATE = 'DIALOG_TRASLATE';