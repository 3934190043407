const catalogos = [{
    name : 'familias',
    caption : 'Marcas',
    url : 'familias'
},{
    name : 'presentaciones',
    caption : 'Modelos',
    url : 'presentaciones'
},{
    name : 'unidadmedida',
    caption : 'Unidad de Medida',
    url : 'ums'
}];



export default catalogos;