const urlReport = () => 
{
    const urlBase = process.env.NODE_ENV == "development" ? 
    "http://reports.eurekani.com" : "http://reports.eurekani.com";

    return {      
        billTicket : id => `${urlBase}/bills?id=${id}`,       
        recibo : id => `${urlBase}/bills/recibos?id=${id}`,       
        commissions : (desde, hasta) =>  `${urlBase}/bills/commission?desde=${desde}&hasta=${hasta}`,   
        products : (desde, hasta, username) =>  `${urlBase}/bills/products?desde=${desde}&hasta=${hasta}&username=${username}`,   
        print : (url) =>  window.open(`${url}`,'_blank'),
        
    }

}
///commission?desde=2021-03-15T00:00:00.00&hasta=2021-03-22T00:00:00.00


export default urlReport;