
import moment from 'moment';
import React from 'react';
import urlReport from '../../../services/reportServices';
import DialogPopup from './DialogPopup';

const Comision = () => {
   
    const getReport = (desde, hasta) => {

        const report = urlReport();
        report.print(`${report.commissions(moment(desde).format('YYYY-MM-DD'), moment(hasta).format('YYYY-MM-DD'))}`);

    } 

    return (
        <div>
            <DialogPopup onClick={getReport} title='Reporte de comisiones'></DialogPopup>
        </div>
    );
}

export default Comision;
