import React, { useState, useEffect, useRef } from "react";
import { Box, DataGrid, DateBox, DropDownBox, SelectBox } from "devextreme-react";
import { useDispatch } from "react-redux";
import { Item } from "devextreme-react/box";
import { Column, Selection, Paging, FilterRow, Scrolling, Export, Pager, Button, Editing  } from "devextreme-react/data-grid";

import http from "../../../utils/http";
import uri from "../../../utils/uri";
import Title from "../../../components/shared/Title";
import BlockHeader from "../../../components/shared/BlockHeader";
import useProducts from "../../../hooks/useProducts";
import { cellRender, cellRenderBold, dataFormatId } from "../../../utils/common";
import { formatDate } from "../../../data/app";
import { addMenu } from "../../../components/grids/Menu";
import { inPutProductTypes, outPutProductTypes } from "../../../data/enums";

import { billDialogAction } from "../../../store/bill/billDialogReducer";
import { dialogOutputProduct } from "../../../store/outPutProduct/outPutProductDialogReducer";
import { dialogInputProduct } from "../../../store/inPutProduct/inPutProductDialogReducer";
import { dialogPurchase } from "../../../store/inPutProductPurchase/purchaseDialogReducer";
import { dialogTraslate } from "../../../store/traslate/traslateDialogReducer";

import * as ViewBill from '../../bills/Nuevo';
import * as ViewAjusteSalida from '../../movimientos/outPutProducts/Nuevo';

import * as ViewSaldoInicial from '../../movimientos/initProducts/Nuevo';
import * as ViewCompras from '../../movimientos/initProducts/NuevoPurchase';

import * as ViewTraslate from '../../movimientos/traslates/Nuevo';

const Kardex = () => {

    let dropDownBoxRef = useRef();

    const [areas, setAreas] = useState([]);
    const [areaId, setAreaId] = useState(0);
    const [date, setDate] = useState(null);
    const {products} = useProducts({ areaId });   
    const [gridBoxValue, setGridBoxValue] = useState([]);  

    const [kardex, setKardex] = useState(undefined);
    const [saldoAnterior, setSaldoAnterior] = useState(0);

    const changeHandler = (e) => {
        setGridBoxValue(e.value);
    }

    const dataGrid_onSelectionChanged = (e) => {        
        setGridBoxValue(e.selectedRowKeys[0].id);
        dropDownBoxRef.current.instance.close();            

    }

    const dataGridRender = () => {
        return (
          <DataGrid
            dataSource={products}
            hoverStateEnabled={true}
            selectedRowKeys={gridBoxValue}
            onSelectionChanged={dataGrid_onSelectionChanged}
            height="100%">            
            <Selection mode="single" />
            <Scrolling mode="infinite" />
            <Paging enabled={true} pageSize={10} />
            <FilterRow visible={true} />
            <Column dataField="id" caption="Codigo" width={80} cellRender={dataFormatId}/>
            <Column dataField="name" caption="Nombre" />
            <Column dataField="family" caption="Marca" width={120}></Column>
            <Column dataField="presentation" caption="Modelo" width={120}></Column>
          </DataGrid>
        );
    }

    const changeArea = e => {
        setAreaId(e.value)
    }

    const changeDate = e => {
        setDate(e.value)
    }

    useEffect(() => {
        http(uri.areas.get).asGet().then(resp => setAreas(resp));
    }, [0]);

    useEffect(() => {
        if(areaId && gridBoxValue && date)
            http('reports/kardex').asPost({areaId, productId : gridBoxValue, date}).then(resp => {
                setKardex(resp.result)
                setSaldoAnterior(resp.saldoAnterior)
            });      
    }, [areaId, gridBoxValue, date]);

    const stockcellRender = (cellData) => {       
        return cellData.value > 0 ? (
            <div className={cellData.data.quantityIn > 0 ? 'inc' : 'dec'} >             
              <div className='diff'>{cellData.value}</div>
            </div>
          ): (<div>{cellData.value}</div>);
    }

    const dispatch = useDispatch();
    const directions = { entrada: 1, salida : 2 };

    const findMove = (e) => {

        const { id, reference, direction, typeId } = e.row.data;

        let codigo = 0;

        if(direction == directions.entrada){
            if([inPutProductTypes.compra, inPutProductTypes.ajusteEntrada, inPutProductTypes.saldoInicial].includes(typeId)){
                codigo = id;
            }else{
                codigo = parseInt(reference);
            }
        }

        if(direction == directions.salida){
            if([outPutProductTypes.facturacion, outPutProductTypes.traslado].includes(typeId)){
                codigo = parseInt(reference);
            }else{
                codigo = id;
            }
        }

        console.log(id, reference, direction, typeId, codigo );

        if(direction == directions.salida){

            if(typeId == outPutProductTypes.facturacion)
                dispatch(billDialogAction({ open: true, id: codigo }));

            if(typeId == outPutProductTypes.ajuste)
                dispatch(dialogOutputProduct({ open: true, id: codigo }));

            if(typeId == inPutProductTypes.traslado)
                dispatch(dialogTraslate({open : true, id: codigo}));
        }

        if(direction == directions.entrada){

            if(typeId == inPutProductTypes.ajusteEntrada)
               dispatch(dialogInputProduct({open : true, id: codigo}));
            
            if(typeId == inPutProductTypes.saldoInicial)
               dispatch(dialogInputProduct({open : true, id: codigo}));

            if(typeId == inPutProductTypes.compra)
               dispatch(dialogPurchase({open : true, id: codigo}));

            if(typeId == inPutProductTypes.traslado)
               dispatch(dialogTraslate({open : true, id: codigo}));
        }
        
    }

    const addMenuItems = (e) => {
        addMenu(e, [{
            text: `Ver movimiento`,
            icon: 'find',
            onItemClick: findMove
        }])
    }

    const title ='Kardex';    

    return (
        <div className="container big">
            <Title title={title} />
            <BlockHeader title={title} />

            <ViewBill.default />
            <ViewSaldoInicial.default type={inPutProductTypes.saldoInicial} />
            <ViewCompras.default type={inPutProductTypes.compra} />

            <ViewAjusteSalida.default />
            <ViewTraslate.default />

            <Box direction="row" width="100%" height={75}>
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Seleccione el area:</label>
                        <SelectBox items={areas}
                            placeholder="Selecciona un producto"
                            showClearButton={true} valueExpr="id" displayExpr="name" 
                            onValueChanged={changeArea}                         
                        />
                    </div>
                </Item>
                <Item ratio={2}>
                    <div className="ml-10">
                        <label>Seleccione un producto:</label>
                        <DropDownBox 
                            ref={dropDownBoxRef}
                            dataSource={products}
                            key="id"
                            placeholder="Selecciona un producto"
                            showClearButton={true} 
                            valueExpr="id"      
                            displayExpr={item => item ? `${item.id} - ${item.name}` : ''} 
                            value = {gridBoxValue}              
                            onValueChanged={changeHandler} 
                            contentRender={dataGridRender}                        
                        />
                    </div>
                </Item>
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Seleccione la fecha de corte:</label>
                        <DateBox type="date" displayFormat={formatDate} openOnFieldClick={true} onValueChanged={changeDate}/>
                    </div>
                </Item>
            </Box>
            <Box direction="row" width="100%" height={75}>

                <Item ratio={1}>
                    <label>Kardex</label>
                    <DataGrid
                        dataSource={[{ stocks : saldoAnterior, type:'Saldo Anterior',id:'-', date: date  }]}
                        showBorders={true}
                        showRowLines={true}
                    >                       
                        <Export enabled={false} fileName={title} allowExportSelectedData={true} />
                        <Column dataField="tem" caption="Opt" width={50} />
                        <Column dataField="type" caption="Saldo Antorior" width={150} />
                        <Column dataField="id" caption="Documento" width={90} alignment="right"/>
                        <Column dataField="date" dataType="date" caption="Fecha" format={formatDate}  width={100} />
                        <Column dataField="reference" caption="Referencia" />                        
                        <Column dataField="stocks" caption="Existencia"  width={80} />
                    </DataGrid>
                    <DataGrid
                        id="gridContainer"
                        selection={{ mode: 'single' }}
                        dataSource={kardex}
                        showBorders={true}
                        showRowLines={true}
                        hoverStateEnabled={true}
                        onContextMenuPreparing={addMenuItems}
                    >                       
                        <Paging defaultPageSize={10} />
                        <Pager
                            showInfo={true}
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 20, 50,100,500]}
                        />
                        <Export enabled={false} fileName={title} allowExportSelectedData={true} />
                        <Column type="buttons" width={50}>
                            <Button name="edit" hint="Ver movimientos"  icon="find" onClick={findMove}/>
                        </Column>
                        <Column dataField="type" caption="Tipo" width={150} />
                        <Column dataField="id" caption="Documento" width={90} />
                        <Column dataField="date" dataType="date" caption="Fecha" format={formatDate}  width={100} />
                        <Column dataField="reference" caption="Referencia" />
                        <Column caption="Entradas" alignment="center">
                            <Column dataField="quantityIn" caption="Cantidad"  width={75} cellRender={stockcellRender}/>
                            <Column dataField="costIn" caption="Costo" cellRender={cellRender()} width={100} alignment="right" />
                            <Column dataField="costTotalIn" caption="Total" cellRender={cellRenderBold()} width={115} alignment="right"/>
                        </Column>                        
                        <Column caption="Salidas" alignment="center">
                            <Column dataField="quantityOut" caption="Cantidad"  width={75} cellRender={stockcellRender}/>
                            <Column dataField="costOut" caption="Costo" cellRender={cellRender()} width={100} alignment="right"/>
                            <Column dataField="costTotalOut" caption="Total" cellRender={cellRenderBold()} width={115} alignment="right"/>
                        </Column>   
                        <Column caption="Existencias" alignment="center">
                            <Column dataField="stocks" caption="Existencia" width={80} />
                            <Column dataField="costAvg" caption="Costo Prom" cellRender={cellRender()} width={100} alignment="right"/>
                            <Column dataField="costPromOut" caption="Total" cellRender={cellRenderBold()} width={115} alignment="right"/>
                        </Column> 
                        <Editing
                            mode="popup"
                            allowUpdating={true}                           
                            useIcons={true}
                        >
                        </Editing>                    
                    </DataGrid>
                </Item>

            </Box>

        </div>
    );
}

export default Kardex;
