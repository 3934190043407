import React, { useEffect, useRef, useState } from "react";
import {
    Paging,
    Pager,
    FilterRow,
    ColumnChooser,
    Column,
    Lookup,
    Export,   
    Button as ButtonGrid,   
    Editing,
    Popup,     
    Form, 
    RequiredRule,
    StringLengthRule, 
}
    from 'devextreme-react/data-grid';
   
import { EmailRule, EmptyItem, Item } from 'devextreme-react/form';
import { DataGrid } from 'devextreme-react';
import BlockHeader from '../../components/shared/BlockHeader';
import Title from "../../components/shared/Title";
import { store } from "../../services/store";
import { dataAccess, editorOptionsNumberBox, editorOptionsSwitch, formatDate, resources, editorOptsTextBoxPhone, formatDateTime } from '../../data/app';
import { createStore, createStoreLocal } from "../../utils/proxy";
import uri from "../../utils/uri";
import useAuthorization from "../../hooks/useAuthorization";
import { cellAsPayoff, cellRender, cellRenderBold, dataFormatId } from "../../utils/common";
import http from "../../utils/http";

const Pending = props => {
   
    const { history } = props;

    const onCellPrepared = e => {

        const cellsQuantity = ['payoff']

        if (e.rowType == 'data' && e.column.allowEditing) {
            if (cellsQuantity.includes(e.column.dataField))
                if (e.row.data.payoff)
                    e.cellElement.classList.add('pagada');
                else
                    e.cellElement.classList.add('pendiente');

            if (e.column.dataField == 'id')
                e.cellElement.classList.add('col-id-factura');
            if (e.column.dataField == 'reference')
                e.cellElement.classList.add('col-reference-factura');
        }

    }

    const title = "Cuentas por cobrar";        

    return (
        <div className="container">
            <Title title={title} />
            <BlockHeader title={title} />           
            <DataGrid
                dataSource={store({uri : { get : 'clients/pending/get' } })}
                selection={{ mode: 'single' }}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                columnHidingEnabled={true}
                allowColumnReordering={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}  
                onCellPrepared={onCellPrepared}        
            >
                <Pager allowedPageSizes={[10, 15, 30, 50]} showPageSizeSelector={true} showInfo={true} />
                <Paging defaultPageSize={15} />
                <FilterRow visible={true} />
                <ColumnChooser enabled={true} />
                <Export enabled={true} fileName={title} allowExportSelectedData={true} />                     
                <Column dataField="id" caption="Id" width={80} cellRender={dataFormatId} />
                <Column dataField="reference" caption="Factura #" width={90} cellRender={dataFormatId} alignment="rigth" />
                <Column dataField="date" caption="Fecha" dataType='date' format={formatDate} width={120} />
                <Column dataField="name" caption="Cliente" allowHeaderFiltering={false} />
                <Column dataField="paymentTypeId" width={100} caption="Tipo pago">
                    <Lookup disabled={true} dataSource={createStoreLocal({ name: 'billPaymentType' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="total" width={110} cellRender={cellRender()} />
                <Column dataField="amount" caption='Pagado' width={110} cellRender={cellRender()} />
                <Column dataField="balance" caption='Saldo' width={110} cellRender={cellRenderBold()} />
                <Column dataField="mora" caption='Mora Dias' width={110} />
                <Column dataField="createBy" caption='Creado por' visible={false} width={100} />
                <Column dataField="createAt" caption='Creado el' dataType='date' visible={false} format={formatDateTime} width={180} />    
                
            </DataGrid>
        </div>
    )


}


export default Pending;
