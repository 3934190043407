import { combineReducers } from 'redux';

import mobileMenuReducer from './mobile-menu';
import sidebarReducer from './sidebar';
import userReducer from './user'
import compraReducer from './compra'
import facturaReducer from './factura'
import servicioReducer from './servicio'
import appReducer from './app';
import catalogReducer from './catalogs';
import inPutProductDialogReducer from './inPutProduct';
import purchaseDialogReducer from './inPutProductPurchase';
import outPutProductDialogReducer from './outPutProduct';
import productDialogReducer from './product';
import customDialogReducer from './customDialog';
import billDialogReducer from './bill';
import dialogReciboReducer from './dialogRecibo';
import areaProductDialogReducer from './areaProduct';
import traslateDialogReducer from './traslate';

export default combineReducers({
    mobileMenu: mobileMenuReducer,
    sidebar: sidebarReducer,    
    user: userReducer,
    compra: compraReducer,
    factura: facturaReducer,
    servicio: servicioReducer,
    appInfo: appReducer, 
    inPutProductDialog : inPutProductDialogReducer,
    outPutProductDialog : outPutProductDialogReducer,  
    productDialog : productDialogReducer,
    catalog : catalogReducer,   
    customDialog : customDialogReducer,
    billDialog: billDialogReducer,
    dialogRecibo : dialogReciboReducer,
    areaProductDialog : areaProductDialogReducer,
    purchaseDialog : purchaseDialogReducer,
    traslateDialog : traslateDialogReducer,
});