import React, { useRef } from 'react';
import { DataGrid } from 'devextreme-react';
import {
    Paging,
    Pager,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
    Column,
    Lookup,
    Editing,
    Button as ButtonGrid,
    Export
} from 'devextreme-react/data-grid';
import { createStoreLocal } from '../../utils/proxy';
import Title from '../../components/shared/Title';
import BlockHeader from '../../components/shared/BlockHeader';
import { store } from '../../services/store';

import CustomButton from '../../components/buttons/CustomButton';
import { _path } from "../../data/headerNavigation";
import { dataAccess, formatDate, formatDateTime, resources } from '../../data/app';
import { cellAsPayoff, cellRender, dataFormatId, } from '../../utils/common';
import urlReport from '../../services/reportServices';
import useAuthorization from '../../hooks/useAuthorization';
import { billDialogAction } from '../../store/bill/billDialogReducer';
import Nuevo from './Nuevo';
import { useDispatch } from 'react-redux';

import uri from '../../utils/uri';

const Bills = props => {

    const { clientId = 0 } = props;

    const { authorized } = useAuthorization([resources.facturacion, dataAccess.access]);
    const dispatch = useDispatch();
    let refGrid = useRef();

    const addMenuItems = (e) => {

        if (e.target == "content") {

            // e.items can be undefined
            if (!e.items) e.items = [];

            // Add a custom menu item
            if (e.rowIndex >= 0)
                e.items.push({

                    text: `Ver factura`,
                    icon: 'find',
                    onItemClick: () => {
                        showDialog(e.row.data.id);                        
                    }

                },{

                    text: `Imprimir factura`,
                    icon: 'print',
                    onItemClick: () => {
                        const report = urlReport();
                        report.print(`${report.billTicket(e.row.data.id)}`);
                    }

                }, {

                    text: `Anular factura`,
                    icon: 'remove',
                    onItemClick: () => refGrid.current.instance.deleteRow(e.rowIndex),
                    color: 'red'
                }, {
                    text: `Pagar factura`,
                    icon: 'fas fa-file-invoice-dollar',
                    onItemClick: () => {



                    },
                });

        }
    }

    const onRowPrepared = (e) => {
        if (e.rowType == 'data') {
            if (!e.data.active)
                e.rowElement.classList.add('no-activo');

        }
    }

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Exportar a excel',
                icon: 'xlsxfile',
                type: 'success',
                stylingMode: "outlined",
                onClick: () => refGrid.current.instance.exportToExcel(false)
            }
        });
    }

    const onCellPrepared = e => {

        const cellsQuantity = ['payoff']

        if (e.rowType == 'data' && e.column.allowEditing) {
            if (cellsQuantity.includes(e.column.dataField))
                if (e.row.data.payoff)
                    e.cellElement.classList.add('pagada');
                else
                    e.cellElement.classList.add('pendiente');

            if (e.column.dataField == 'id')
                e.cellElement.classList.add('col-id-factura');
            if (e.column.dataField == 'reference')
                e.cellElement.classList.add('col-reference-factura');
        }

    }

    const showDialog = (id) => dispatch(billDialogAction({ open: true, id }))

    const onSave = (e) => refGrid.current.instance.refresh();

    const extraParameter = clientId > 0 ? { key: 'clientId', value: clientId } : null;

    const title = 'Facturas';

    return authorized(
        <div className="container">
            <Title title={title} />
            <BlockHeader title={title}>
                <CustomButton
                    type="default"
                    text={`Nueva factura`}
                    icon='plus'
                    onClick={() => showDialog(0)}
                />
                <CustomButton
                    type="default"
                    text={`Factura rapida`}
                    icon='pin'
                    onClick={() => props.history.push('/app/facturas-quickly')}
                />
            </BlockHeader>
            <Nuevo onSave={onSave} { ...props } />
            <DataGrid id="gridContainer"
                ref={refGrid}
                selection={{ mode: 'single' }}
                dataSource={store({ uri: uri.bills, remoteOperations: true, extraParameter })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                rowAlternationEnabled={true}
                onContextMenuPreparing={addMenuItems}
                onRowPrepared={onRowPrepared}
                onToolbarPreparing={onToolbarPreparing}
                onCellPrepared={onCellPrepared}
                remoteOperations={{
                    paging: true,
                    filtering: true
                }}
            >
                <Paging defaultPageSize={20} />
                <Pager
                    showInfo={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                />
                <Export enabled={false} fileName={title} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <Column dataField="id" caption="Id" width={90} cellRender={dataFormatId} />
                <Column dataField="reference" caption="Factura #" width={90} cellRender={dataFormatId} alignment="rigth" />
                <Column dataField="date" caption="Fecha" dataType='date' format={formatDate} width={120} />
                <Column dataField="nombre" caption="Cliente" visible={clientId == 0} allowHeaderFiltering={false} />
                <Column dataField="paymentTypeId" width={150} caption="Tipo pago">
                    <Lookup disabled={true} dataSource={createStoreLocal({ name: 'billPaymentType' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="total" width={110} cellRender={cellRender()} />
                <Column dataField="payoff" width={100} caption='Pagada' dataType="boolean" cellRender={cellAsPayoff} />
                <Column dataField="createBy" caption='Creado por' width={100} />
                <Column dataField="createAt" caption='Creado el' dataType='date' format={formatDateTime} width={180} />
                <Column type="buttons" width={60}>
                    <ButtonGrid name="edit" icon="find" onClick={e => showDialog(e.row.data.id)}/>
                    <ButtonGrid name="delete" />
                </Column>
                <Editing
                    mode="popup"
                    allowDeleting={true}
                    allowUpdating={true}
                    useIcons={true}
                ></Editing>
            </DataGrid>
        </div>
    );
}

export default Bills;
