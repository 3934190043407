import React, { useEffect, useRef, useState } from 'react';
import { DataGrid } from 'devextreme-react';
import {
    Paging,
    Pager,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
    Column,
    Lookup,
    Editing,
    Button as ButtonGrid,
    MasterDetail,
    Summary,
    TotalItem
} from 'devextreme-react/data-grid';
import { createStoreLocal } from '../../utils/proxy';
import Title from '../shared/Title';
import BlockHeader from '../shared/BlockHeader';
import { store } from '../../services/store';

import CustomButton from '../buttons/CustomButton';
import { _path } from "../../data/headerNavigation";
import { dataAccess, formatDate, formatDateTime, resources } from '../../data/app';
import toCapital, { cellAsPayoff, cellRender, dataFormatId, formatId, formatToMoney } from '../../utils/common';
import urlReport from '../../services/reportServices';
import useAuthorization from '../../hooks/useAuthorization';
//import { openDialog } from '../../store/customDialog/customDialogReducer';
import { useDispatch } from 'react-redux';
import http from '../../utils/http';
import Nuevo from './Nuevo';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import DetailTemplate from './DetailTemplate';
import { onCellPreparedReceipt } from './CellPrepared';

const BillCxC = props => {

    const { clientId = 0 } = props;
    const [balance, setBalance] = useState(0);
    const { authorized } = useAuthorization([resources.facturacion, dataAccess.access]);
    const [ dataDialog, setDataDialog ] = useState({ id: 0, billId: 0, open: false, client : '', balance : ''});

    const refGrid = useRef();

    const addMenuItems = (e) => {

        if (e.target == "content") {

            if (!e.items) e.items = [];

            if (e.rowIndex >= 0) {

                e.items.push({

                    text: `Crear recibo de caja`,
                    icon: 'doc',
                    onItemClick: () => {                      
                        openDialog(e);
                    }

                },{

                    text: `Ver factura`,
                    icon: 'doc',
                    onItemClick: () => {                      
                        //openDialog(e);
                    }

                });
            }

        }
    }

    const openDialog = e => {
        setDataDialog({ id: 0, billId: e.row.data.id, open: true, client : e.row.data.nombre, balance : e.row.data.balance});
    }

    const calculareBalance = (params) => {
        http(`bills/${clientId}/cxc/get`).asGet().then(resp => {            
            setBalance(resp.reduce((accumulator, currentValue) => accumulator + currentValue.balance,0))
        });
    }

    const onSave = e =>{
        refGrid.current.instance.refresh();
        calculareBalance();
    }

    const close = e =>{
        setDataDialog({ id: 0, billId: 0, open: false, client : '', balance : ''});
    }

    useEffect(() => {
        calculareBalance();
    }, [clientId]);

    const title = 'Facturas de credito pendientes';

    return authorized(
        <div >
            <BlockHeader title={title}>
                <p style={{fontSize : 28}}>Deuda total : {formatToMoney(balance)}</p>
            </BlockHeader>

            <Nuevo data={dataDialog} close={close} onSave={onSave}/>

            <DataGrid id="grid"
                ref={refGrid}
                selection={{ mode: 'single' }}
                dataSource={store({ uri: { get: `bills/${clientId}/cxc/get` }, })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                rowAlternationEnabled={true}
                onCellPrepared={onCellPreparedReceipt}
                onContextMenuPreparing={addMenuItems}
            >
                <Paging defaultPageSize={20} />
                <Pager
                    showInfo={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Column type="buttons" width={80}>
                    <ButtonGrid name="modificar" text="Pagar" onClick={openDialog}/>
                </Column>
                <Column dataField="id" caption="Factura #" width={100} cellRender={dataFormatId} />
                <Column dataField="date" caption="Fecha" dataType='date' format={formatDate} width={100} />             
                <Column dataField="paymentTypeId" caption="Tipo pago">
                    <Lookup disabled={true} dataSource={createStoreLocal({ name: 'billPaymentType' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="total" width={120} cellRender={cellRender()} />
                <Column dataField="amount" caption="Pagado" width={120} cellRender={cellRender()} />
                <Column dataField="balance" caption="Pendiente" width={120} cellRender={cellRender()} />
                <Column dataField="daysInDebt" caption="En mora" width={80}/>
                <Column dataField="payoff" width={120} caption='Estado' dataType="boolean" cellRender={cellAsPayoff} />
                <Column dataField="createBy" caption='Creado por' width={100} visible={false} />
                <Column dataField="createAt" caption='Creado el' dataType='date' format={formatDateTime} width={180} visible={false} />
                <Summary>                
                    <TotalItem
                        column="balance"
                        summaryType="sum" customizeText={data => `Total: ${formatToMoney(data.value)}`} />
                </Summary>
                <MasterDetail
                    enabled={true}
                    component={props => <DetailTemplate onSave={onSave} {...props}/>}
                />
            </DataGrid>
        </div>
    );
}

export default BillCxC;
